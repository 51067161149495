export const {
  REACT_APP_METABASE_FEEDBACK_ON_TF_PROFESSIONAL = 0,
  REACT_APP_METABASE_AGE_BREAKDOWN = 0,
  REACT_APP_METABASE_GENDER_BREAKDOWN = 0,
  REACT_APP_METABASE_TOP_CONCERNS_BREAKDOWN = 0,
  REACT_APP_METABASE_LOCATION_BREAKDOWN = 0,
  REACT_APP_METABASE_USER_ACQUISITION = 0,
  REACT_APP_METABASE_USER_OUTCOME_BREAKDOWN = 0,
  REACT_APP_METABASE_CLINICAL_INSIGHTS = 0,
  REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH = 0,
  REACT_APP_METABASE_MOOD_OBSERVATION_GROUPED_BY_MOOD = 0,
  REACT_APP_METABASE_MOOD_HEATMAP = 0,
  REACT_APP_METABASE_REASON_MOOD = 0,
  REACT_APP_METABASE_MOOD_BREAKDOWN = 0,
  REACT_APP_METABASE_FREQUENCY_POSITIVE_MOOD_REASONS = 0,
  REACT_APP_METABASE_FREQUENCY_NEGATIVE_MOOD_REASONS = 0,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG = 0,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE = 0,
  REACT_APP_METABASE_FWD_METRICS = 0,
  REACT_APP_METABASE_AIA_METRICS = 0,
  REACT_APP_METABASE_CLINICAL_OUTCOMES_DASS_21 = 0,
  REACT_APP_METABASE_CLINICAL_OUTCOMES_OTHER_ASSESSMENTS = 0,
  REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED = 0,
  REACT_APP_METABASE_AIA_AVG_DASS_SCORES = 0,
  REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT = 0,
  REACT_APP_METABASE_AIA_TOP_CONCERT = 0,
  REACT_APP_METABASE_AIA_BREAKDOWN_GENDER = 0,
  REACT_APP_METABASE_AIA_BREAKDOWN_AGE = 0,
  REACT_APP_METABASE_AIA_TOTAL_REGISTER_USERS = 0,
  REACT_APP_METABASE_AIA_OVERALL_USER_RATING = 0,
  REACT_APP_METABASE_AIA_ACTIVATION_PROGRAMME_WEBINARS = 0,
  REACT_APP_METABASE_AIA_TOP_3_SELECTED_TOPICS = 0,
  REACT_APP_METABASE_AIA_TOP_USER_OUTCOMES = 0,
  REACT_APP_METABASE_AIA_NUMBER_COMPLETED_DASS = 0,
} = process.env;
